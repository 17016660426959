import React, { useState, useMemo, useEffect, useCallback } from "react";
import { createContext } from "@fluentui/react-context-selector";
import { SnackbarProvider } from "notistack";
import PropTypes from "prop-types";

export const siteContext = createContext();

function Provider({ children }) {
  const [darkTheme, setDarkTheme] = useState(false);

  useEffect(() => {
    const hours = new Date().getHours();
    const isDayTime = hours >= 7 && hours < 20;
    setDarkTheme(!isDayTime);
  }, []);

  const updateTheme = useCallback(
    themeBool => setDarkTheme(themeBool),
    [setDarkTheme]
  );

  return (
    <siteContext.Provider
      value={useMemo(
        () => ({
          darkTheme,
          updateTheme
        }),
        [darkTheme, updateTheme]
      )}
    >
      {children}
    </siteContext.Provider>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
export default ({ element }) => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      horizontal: "center",
      vertical: "top"
    }}
  >
    <Provider>{element}</Provider>
  </SnackbarProvider>
);

Provider.propTypes = {
  children: PropTypes.node.isRequired
};
